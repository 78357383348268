/*! Universal UHC Styles */
@import "variables";
body {
    color: $uhc-black;
    font-size: 18px;
}
/* Header Styling */
.gold-emphasis {
    color: $uhc-gold;
}
.usa-header-basic {
    box-shadow: 0 1px 28px rgba(0,0,0,.1), 0 1px 5px rgba(0,0,0,.05);
}
@media (max-width: 1596px) {
    .page-heading .container {
        padding-left: 16px;
        padding-right: 16px;
    }
}
.page-heading {
    @media (max-width: 1600px) {
        & > .container {
            padding-left: 16px;
        }
    }
    h1, h2, p {
        text-shadow: 1px 1px 8px rgba(0,0,0,.4);
        max-width: 680px;
    }
    h1 .gold-emphasis {
        text-shadow: 1px 1px 8px rgba(0,0,0,.5), 1px 1px 6px rgba(238,169,4,.45);
    }
    ul.et_social_icons_container {
        li {
            margin-top: 0!important;
            margin-left: 2%!important;
            &:first-of-type {
                margin-left: 0!important;
            }
        }
    }
    @media (max-width: 600px) {
        background-color: rgba(0,92,181,.3)!important;
        ul.et_social_icons_container {
            max-width: 200px;
        }
    }
    h1, h2 {
        font-weight: normal!important;
        strong {
            font-weight: normal!important;
            letter-spacing: -1px!important;
            line-height: 0.85;
        }
    }
    p strong {
        font-weight: bold!important;
    }
}
@media (max-width: 600px) {
    .blog-heading {
        padding: 14% 0!important;
    }
    .et_monarch .et_social_inline_top {
        margin-bottom: 15px!important;
    }
}
.blog-heading > .container {
    max-width: 1320px;
    @media (min-width: 650px) {
        min-height: 300px;
    }
}
body.single-post {
    pre {
        padding: 0.5em 1em;
        background-color: white;
        border-radius: 6px;
        font-family: unset;
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1);
        code {
            font-family: monospace;
            border-radius: 6px;
            background-color: #f7f7f7;
            padding: 0.5em 1em;
            display: inline-block;
            margin: 0.5em 1em 0.5em 0;
            color: darkred;
        }
    }
    ol {
        padding-left: 45px!important;
        li {
            padding-top: 0.25em;
            font-size: 1em!important;
            font-weight: normal!important;
            &:before {
                color: $uhc-blue;
                top: 0.5px!important;
                font-size: 1.25em!important;
                left: -52.5px!important;
                width: 40px;
                text-align: right;
            }
        }
    }
    blockquote:not(.card) {
        font-size: 1.25em;
        border-left: 2px solid $uhc-gold;
        padding-left: 1em;
        margin-inline-start: 25px;
        color: $uhc-blue;
    }
    article.post-type-post > p:last-child {
        font-size: 14px;
        line-height: 1.3;
    }
}
body.single {
    aside {
        @media (min-width: 1200px) {
            margin-top: -190px;
        }
    }
    .card > p {
        margin: 0;
    }
}
.no-margin {
    margin: 0;
}
img.inline-image {
    margin: 1.5em auto 3em;
    box-shadow: 0 20px 50px -15px rgba(0,0,0,.4);
    width: auto;
    height: auto;
    border-radius: 6px;
    @media (max-width: 992px) {
        box-shadow: 0 10px 20px -10px rgba(0,0,0,.4);
        margin-bottom: 1.5em;
    }
}
/* Button Styling */
.usa-button, .usa-button-primary, .card-button, .my-chart, .newsletter-button {
    &.gold, &.uhc-gold {
        background-color: $uhc-gold!important;
        box-shadow: 0px 1px 6px -1px transparentize($uhc-gold, 0.55);
        transition: 0.2s ease-in-out;
        &:hover {
            background-color: lighten($uhc-gold, 5%)!important;
            box-shadow: 0px 3px 10px 0px transparentize(lighten($uhc-gold, 5%), 0.6);
            transform: translateY(-1px);
            color: white;
        }
        &:focus, &:active {
            transform: translateY(0px);
            background-color: $uhc-gold!important;
            box-shadow: 0px 1px 6px -1px transparentize($uhc-gold, 0.25)!important;
        }
    }
    &.blue, &.uhc-blue {
        background-color: $uhc-blue!important;
        box-shadow: 0px 1px 6px -1px transparentize($uhc-blue, 0.55);
        transition: 0.2s ease-in-out;
        &:hover {
            background-color: lighten($uhc-blue, 5%)!important;
            box-shadow: 0px 3px 10px 0px transparentize(lighten($uhc-blue, 5%), 0.6);
            transform: translateY(-1px);
            color: white;
        }
        &:focus, &:active {
            transform: translateY(0px);
            background-color: $uhc-blue!important;
            box-shadow: 0px 1px 6px -1px transparentize($uhc-blue, 0.25)!important;
        }
    }
}
/* Sidebar Styling */
aside .sb-links ul li {
    .oncology {
        background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(23,55,92,.71)),to(rgba(23,55,92,.66))),url(https://wvcancercenter.com/wp-content/uploads/2018/01/home-header.jpg);
        background-image: linear-gradient(rgba(23,55,92,.71),rgba(23,55,92,.66)),url(https://wvcancercenter.com/wp-content/uploads/2018/01/home-header.jpg);
    }
    .ortho {
        background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(23,55,92,.71)),to(rgba(23,55,92,.66))),url(https://wvorthocenter.com/wp-content/uploads/2019/10/uhc-ortho-hp-new-header-rocco-m3.jpg);
        background-image: linear-gradient(rgba(23,55,92,.71),rgba(23,55,92,.66)),url(https://wvorthocenter.com/wp-content/uploads/2019/10/uhc-ortho-hp-new-header-rocco-m3.jpg);
    }
    .emergency {
        background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(23,55,92,.71)),to(rgba(23,55,92,.66))),url(https://uhcemergencyroom.com/wp-content/uploads/2018/01/uhc-er-header3c.jpg);
        background-image: linear-gradient(rgba(23,55,92,.71),rgba(23,55,92,.66)),url(https://uhcemergencyroom.com/wp-content/uploads/2018/01/uhc-er-header3c.jpg);
        background-position: bottom;
    }
}
/* Infowindow Styling */
.infowindow {
    border-radius: 6px;
    color: lighten($uhc-black, 20%);
    p strong {
        color: $uhc-blue;
    }
}
/* Google Maps Marker Styling */
.gm-style .gm-style-iw-c {
    box-shadow: 0 20px 30px rgba(0,0,0,.1);
    color: $uhc-blue;
    font-size: 16px;
    & > div {
        padding: 0.2em;
    }
    a {
        opacity: 1!important;
        color: $uhc-blue;
        font-size: 16px;
        &:hover {
            color: lighten($uhc-blue, 5%);
            text-decoration: none;
        }
    }
}
// Footer Styling
footer {
    .footer-column {
        h5 {
            & + .textwidget {
                p, ul {
                    margin-top: 8px;
                }
            }
            & + ul, & + p {
                margin-top: 8px;
            }
        }
        &:first-of-type {
            .textwidget strong {
                display: inline-block;
                line-height: 1.4;
            }
        }
        @media (min-width: 1300px) {
            padding-right: 100px!important;
        }
    }
    a.calling-card {
        font-weight: normal!important;
        font-size: 36px!important;
    }
}
@media (max-width: $breakpoint-phone) {
    h1 {
        letter-spacing: 0px;
    }
    h2 {
        letter-spacing: -1px;
    }
}
// mobile menu toggle button
.usa-menu-btn, .usa-nav-close {
	background-color: $uhc-gold;
    border-radius: 100%;
    box-shadow: $box-shadow-button;
    color: #FFF !important;
    max-width: 4rem;
    transition: $global-transition;
    &:hover {
        background-color: lighten($uhc-gold, 5%);
    }
}
body.single-doctors {
    main {
        margin-top: 3em;
    }
    .post-type-doctors {
        h3 > p {
            margin-top: .15em;
            line-height: 1.2;
        }
        .dr-img {
            border-radius: 10px;
        }
        .dr-contact {
            margin-top: 1.5em;
            margin-bottom: 1.25em;
            max-width: 325px!important;
            margin-left: auto!important;
            margin-right: auto!important;
            border-radius: 6px!important;
            .content {
                padding: 22px 32px!important;
                & > p:last-of-type {
                    margin-bottom: 0.25em!important;
                }
            }
        }
        .col.m2 > .card.simple {
            margin-top: 0!important;
            .no-bullet {
                margin-bottom: 0.2em!important;
            }
        }
        .card a:hover {
            text-decoration: none!important;
        }
    }
}
@media (max-width: 768px) {
	.intro-p {
		font-size: 20px!important;
	}
}
.doc-card, .navigator-card {
    max-width: 435px;
    margin: 0 auto;
}
.doc-card {
    margin-bottom: 25px;
    .doc-blue > div:nth-of-type(2) {
        padding-right: 8px;
        padding-bottom: 0;
    }
}
.treatment-card, .welcome-card, .tease.card {
    max-width: 600px;
    margin: 0 auto;
}
// Aside Styling
aside.post-sidebar {
    border-radius: 6px 6px 0 0;
    .textwidget .lead {
        text-align: center;
    }
    & > div {
        border-radius: 6px 6px 0 0;
    }
    & > * {
        margin: 0 auto;
        max-width: 466px;
    }
}
aside:not(.post-sidebar):not(#info) {
    margin: 0 auto;
    max-width: 466px;
}
ul.jump-links {
    border: 0.5px solid $color-gray-lightest!important;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 690px) and (min-width: 992px) {
        border-bottom: none!important;
    }
    li {
        width: 100%;
        @media (min-width: 690px) and (max-width: 992px) {
            width: 50%;
            &:nth-of-type(2n+1) {
                border-right: 0.5px solid #f1f1f1;
            }
        }
        &:last-child {
            border-radius: 6px;
            @media (min-width: 690px) and (max-width: 992px) {
                border-radius: 0px 0px 0px 6px;
                border-bottom: none;
            }
        }
    }
}
.section-blogs {
    .tease.card {
        max-width: 600px;
        margin: 0 auto;
    }
    h2.gold-slash {
        @media (max-width: 922px) {
            margin-bottom: 1em;
        }
    }
}
h2.gold-slash {
    font-weight: normal!important;
}
.text-col-2 {
    li {
        display: inline-block;
        width: 100%;
    }
}
.lity {
    background: rgba(0, 19, 41, 0.85)!important;
}
.card {
    &:not(.no-hover):not(.quote-card):hover {
        transform: translate(-1px, -1px)!important;
    }
    &.no-hover {
        transform: translate(0px)!important;
    }
}

ul.styled {
    @include StyledList;
    z-index: 55555;
    li {
        & > ul {
            margin-top: 1em;
            margin-bottom: 0;
            & > li {
                &:last-of-type {
                    border-bottom: none;
                    margin-bottom: 0!important;
                }
            }
            &:before {
                content: "";
                position: absolute;
                left: -0.2em;
                top: 0.5em;
                bottom: 1em;
                border-left: 1px solid rgba(238, 169, 4, 0.3);
            }
        }
    }
}
.post-type-doctors .treatment-card, .blog-video-card {
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    border-radius: 6px;
    overflow: hidden;
    max-width: 600px;
    margin: 2em auto;
    & > p {
        display: none;
    }
    & > a {
        width: 100%;
        text-decoration: none;
        & > p {
            display: none;
        }
    }
    &:hover {
        .treatment-wrap{
             &:after {
                background: rgba(0, 40, 87, 0);
                transition: $global-transition;
            }
            .treatment-still {
                filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
                filter: grayscale(0%);
                filter: gray;
                transition: $global-transition;
            }
         }
    }
    .treatment-wrap {
        width: 100%;
        overflow: hidden;
        position: relative;
        .treatment-still {
            filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
            filter: gray;
            transition: $global-transition;
            display: block;
            min-width: 100%;
            width: 100%;
            position: relative;
        }
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 40, 87, 0.30);
            transition: $global-transition;
        }
    }
    .bottom-treatment {
        z-index: 50!important;
        position: relative!important;
        background-color: white!important;
        max-width: 100%;
	    padding: 32px;
	    &:before {
            content: "";
            position: absolute;
            width: 60px;
            height: 60px;
            top: -1.75em;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-image: url(https://uhcspecialties.com/wp-content/uploads/2019/12/play-button.png);
            background-size: contain;
            background-repeat: no-repeat;
        }
	    p:first-child {
    	    margin: 10px 0px 0px 0px;
    	    width: 100%;
    	    -ms-flex: 0 1 auto;
    	}
    }
    h5 {
        display: inline-block;
        height: fit-content;
        width: 65%;
        line-height: 1.1;
        margin: auto;
        position: absolute;
        font-size: 21px;
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
        font-weight: 600;
        top: 0;
        color: $uhc-blue;
        bottom: 0;
        @media (min-width: 600px) {
            font-size: 23px;
        }
        span {
            font-size: 0.6em;
            font-weight: 600;
            color: $uhc-blue;
        }
	}
}

.sidebar-card, .post-sidebar, .sidebar-card-lp {
    &.housecall {
        padding: 0;
        margin-bottom: 1em;
        border-radius: 6px!important;
        overflow: hidden;
        .sb-links {
            ul {
                padding-left: 0!important;
                li {
                    padding: 0!important;
                    &:before {
                        content: ""!important;
                    }
                }
            }
        }
        .textwidget {
            padding: 24px!important;
            padding-top: 0!important;
        }
        & > .sidebar-widget:first-of-type {
            background-image: url(https://www.uhcspecialties.com/wp-content/themes/uhc-uswds/img/housecall_background.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top;
            background-color: rgba(255, 255, 255, 0.4);
            background-blend-mode: lighten;
            .textwidget {
                padding-top: 24px!important;
            }
            .lead {
                margin: 0.5em 0;
                font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
                font-size: 1.8em;
                line-height: 1.2;
                color: #757575;
                margin-bottom: 0!important;
                @media (min-width: 1200px) and (max-width: 1450px) {
                    font-size: 1.6em;
                    & + p {
                        font-size: 1.2em!important;
                        & + a {
                            font-size: 1.4em!important;
                        }
                    }
                }
                & + p {
                    font-size: 1.4em;
                    margin: 0;
                    line-height: 1.2em;
                    & + a {
                        text-decoration: none;
                        color: $uhc-gold;
                        font-family: 'Helvetica Neue', Helvetica, sans-serif;
                        font-weight: 700;
                        font-size: 1.6em;
                        margin-top: 0.5em;
                        display: block;
                        @media (min-width: $medium-screen) {
                            font-size: 1.8em;
                        }
                        &::after {
                            content: "";
                            background-repeat: no-repeat;
                            width: 1.6em;
                            height: 2.5em;
                            display: inline-block;
                            float: right;
                            margin-top: -0.9em;
                        }
                    }
                }
            }
        }
        .newsletter-button {
            display: block;
            margin: 1em 0 0;
            padding: 0.5em 0.75em;
            text-align: center;
            text-decoration: none;
            background-color: $uhc-gold;
            border-radius: 6px;
            font-family: 'Helvetica Neue', helvetica, sans-serif;
            font-weight: 700;
            color: white;
            font-size: 1.5em;
        }
        @media (max-width: 1199px) {
            margin-top: 20px!important;
        }
    }
    .custom-html-widget > div.housecall {
        background-image: url(https://www.uhcspecialties.com/wp-content/themes/uhc-uswds/img/housecall_background.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        background-color: rgba(255, 255, 255, 0.4);
        background-blend-mode: lighten;
        padding: 1.75em;
        margin-top: 0;
        .lead {
            margin: 0.5em 0;
        }
        .newsletter-button {
            display: block;
            margin: 1em 0 0;
            padding: 0.5em 0.75em;
            text-align: center;
            text-decoration: none;
            background-color: $uhc-gold;
            border-radius: 6px;
            font-family: 'Helvetica Neue', helvetica, sans-serif;
            font-weight: 700;
            color: white;
            font-size: 1.5em!important;
        }
    }
}

.sidebar-card.housecall {
    margin-top: 0!important;
}

.cc-banner {
    z-index: 9999999!important;
}

.cc-window.cc-banner {
    background-color: $uhc-gold!important;
    background-image: linear-gradient(-15deg, #EAAA00, #fdba24)!important;
    color: $uhc-blue!important;
    font-family: $helvetica!important;
    border-top: 1px solid white!important;
    a {
        color: $uhc-blue!important;
        &:hover {
            text-decoration: none!important;
        }
    }
    .cc-btn {
        &.cc-allow {
            background-color: $uhc-blue!important;
            color: white!important;
            transition: $global-transition!important;
            &:hover {
                background-color: lighten($uhc-blue, 5%)!important;
            }
        }
    }
    @media (max-width: 687px) {
        flex-direction: column!important;
        .cc-message {
            margin-left: 1em!important;
            padding: 0.75em;
        }
        .cc-compliance {
            width: 100%;
        }
    }
}

body.blog .ind-blog .archive-card {
    h2 {
        @media (max-width: 600px) {
            font-size: 22px!important;
        }
    }
    &:nth-of-type(1) {
        h2 {
            @media (max-width: 600px) {
                font-size: 26px!important;
            }
        }
    }
}

.content-area .card.callout {
    @media (max-width: 600px) {
        padding-top: 16px!important;
    }
}

.gform_wrapper div.validation_error {
    color: $uhc-red;
    border-color: $uhc-red;
}


.housecall-videos-sidebar {
    h4 {
        font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
        color: $uhc-blue;
        font-size: 28px;
    }
    .card {
        border-radius: 6px;
        margin-bottom: 1em!important;
    }
    .hc-image-container {
        position: relative;
        opacity: 1;
        transition: $global-transition;
        &:after {
            content: url('https://uhchousecall.com/wp-content/themes/uhc-uswds/img/play-button.svg');
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            position: absolute;
            height: 60px;
            width: 60px;
            transition: $global-transition;
            pointer-events: none;
            filter: drop-shadow(0 2px 12px rgba(0,0,0,.5));
        }
    }
    img {
        display: block;
        border-radius: 6px 6px 0 0;
    }
    a {
        text-decoration: none;
        p {
            margin: 0;
            padding: 1.25em;
            color: #8e99ad!important;
        }
        &:hover {
            .hc-image-container {
                opacity: 0.9;
                &:after {
                    transform: scale(1.05);
                }
            }
        }
    }
    @media (max-width: 992px) {
        .card {
            box-shadow: 0 20px 30px rgba(0,0,0,.1), 0 2px 10px -2px rgba(0,0,0,.05)!important;
        }
    }
}

.uhc-flex-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.75em 0;
    & > div:first-of-type {
        margin-right: 1.5em;
    }
    &.reverse {
        flex-direction: row-reverse;
        & > div:first-of-type {
            margin-right: 0;
        }
        & > div:nth-of-type(2) {
            margin-right: 1.5em;
        }
    }
    .flex-container__portrait {
        flex: 1;
        max-width: 350px;
        img {
            width: 100%;
            height: auto;
            display: block;
            max-width: 400px;
            &.circle {
                border-radius: 100%;
            }
        }
    }
    .flex-container__image {
        flex: 2;
        max-width: 450px;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    .flex-container__content {
        flex: 3;
        &.larger {
            p {
                font-size: 1.2em;
            }
        }
        &.blue {
            p {
                color: $uhc-blue;
            }
        }
    }
    @media (max-width: 768px) {
        flex-direction: column;
        &.reverse {
            flex-direction: column;
        }
        &.mobile-reverse {
            flex-direction: column-reverse;
        }
    }
}


.uhc-bar {
    cursor: default;
    .usa-nav-container {
        p {
            cursor: default;
        }
    }
}
.social-link {
    display: inline-flex!important;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    width: 1.35em;
    height: 1.35em;
    font-size: 1.05em!important;
    opacity: 0.85;
    transition: $global-transition;
    text-decoration: none!important;
    color: white!important;
    &:hover {
        opacity: 1;
    }
    &:last-of-type {
        margin-right: 0;
    }
}
.social-link--container {
    margin-bottom: 1em;
    cursor: default;
    .social-link {
        font-size: 1.35em!important;
        &:first-of-type {
            margin-left: 0;
        }
    }
}
.mobile-social-menu {
    display: flex!important;
    padding: 0 0 12px 25px;
    border-bottom: 1px solid #0033a0;
    justify-content: flex-end;
    .social-link {
        border: none!important;
        width: 2em;
        height: 2em;
        padding: 0!important;
        font-size: 1.25em!important;
        background-color: transparent;
        &:hover {
            background-color: transparent;
        }
    }
    @media (min-width: 950px) {
        display: none!important;
    }
}