@charset "UTF-8";
/*! Universal UHC Styles */
.helvetica-condensed {
  font-family: "Helvetica Neue Condensed", Helvetica, sans-serif !important; }

body {
  color: #2b2b2b;
  font-size: 18px; }

/* Header Styling */
.gold-emphasis {
  color: #eea904; }

.usa-header-basic {
  box-shadow: 0 1px 28px rgba(0, 0, 0, 0.1), 0 1px 5px rgba(0, 0, 0, 0.05); }

@media (max-width: 1596px) {
  .page-heading .container {
    padding-left: 16px;
    padding-right: 16px; } }

@media (max-width: 1600px) {
  .page-heading > .container {
    padding-left: 16px; } }

.page-heading h1, .page-heading h2, .page-heading p {
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4);
  max-width: 680px; }

.page-heading h1 .gold-emphasis {
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5), 1px 1px 6px rgba(238, 169, 4, 0.45); }

.page-heading ul.et_social_icons_container li {
  margin-top: 0 !important;
  margin-left: 2% !important; }
  .page-heading ul.et_social_icons_container li:first-of-type {
    margin-left: 0 !important; }

@media (max-width: 600px) {
  .page-heading {
    background-color: rgba(0, 92, 181, 0.3) !important; }
    .page-heading ul.et_social_icons_container {
      max-width: 200px; } }

.page-heading h1, .page-heading h2 {
  font-weight: normal !important; }
  .page-heading h1 strong, .page-heading h2 strong {
    font-weight: normal !important;
    letter-spacing: -1px !important;
    line-height: 0.85; }

.page-heading p strong {
  font-weight: bold !important; }

@media (max-width: 600px) {
  .blog-heading {
    padding: 14% 0 !important; }
  .et_monarch .et_social_inline_top {
    margin-bottom: 15px !important; } }

.blog-heading > .container {
  max-width: 1320px; }
  @media (min-width: 650px) {
    .blog-heading > .container {
      min-height: 300px; } }

body.single-post pre {
  padding: 0.5em 1em;
  background-color: white;
  border-radius: 6px;
  font-family: unset;
  box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.1); }
  body.single-post pre code {
    font-family: monospace;
    border-radius: 6px;
    background-color: #f7f7f7;
    padding: 0.5em 1em;
    display: inline-block;
    margin: 0.5em 1em 0.5em 0;
    color: darkred; }

body.single-post ol {
  padding-left: 45px !important; }
  body.single-post ol li {
    padding-top: 0.25em;
    font-size: 1em !important;
    font-weight: normal !important; }
    body.single-post ol li:before {
      color: #002857;
      top: 0.5px !important;
      font-size: 1.25em !important;
      left: -52.5px !important;
      width: 40px;
      text-align: right; }

body.single-post blockquote:not(.card) {
  font-size: 1.25em;
  border-left: 2px solid #eea904;
  padding-left: 1em;
  margin-inline-start: 25px;
  color: #002857; }

body.single-post article.post-type-post > p:last-child {
  font-size: 14px;
  line-height: 1.3; }

@media (min-width: 1200px) {
  body.single aside {
    margin-top: -190px; } }

body.single .card > p {
  margin: 0; }

.no-margin {
  margin: 0; }

img.inline-image {
  margin: 1.5em auto 3em;
  box-shadow: 0 20px 50px -15px rgba(0, 0, 0, 0.4);
  width: auto;
  height: auto;
  border-radius: 6px; }
  @media (max-width: 992px) {
    img.inline-image {
      box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.4);
      margin-bottom: 1.5em; } }

/* Button Styling */
.usa-button.gold, .usa-button.uhc-gold, .usa-button-primary.gold, .usa-button-primary.uhc-gold, .card-button.gold, .card-button.uhc-gold, .my-chart.gold, .my-chart.uhc-gold, .newsletter-button.gold, .newsletter-button.uhc-gold {
  background-color: #eea904 !important;
  box-shadow: 0px 1px 6px -1px rgba(238, 169, 4, 0.45);
  transition: 0.2s ease-in-out; }
  .usa-button.gold:hover, .usa-button.uhc-gold:hover, .usa-button-primary.gold:hover, .usa-button-primary.uhc-gold:hover, .card-button.gold:hover, .card-button.uhc-gold:hover, .my-chart.gold:hover, .my-chart.uhc-gold:hover, .newsletter-button.gold:hover, .newsletter-button.uhc-gold:hover {
    background-color: #fbb611 !important;
    box-shadow: 0px 3px 10px 0px rgba(251, 182, 17, 0.4);
    transform: translateY(-1px);
    color: white; }
  .usa-button.gold:focus, .usa-button.gold:active, .usa-button.uhc-gold:focus, .usa-button.uhc-gold:active, .usa-button-primary.gold:focus, .usa-button-primary.gold:active, .usa-button-primary.uhc-gold:focus, .usa-button-primary.uhc-gold:active, .card-button.gold:focus, .card-button.gold:active, .card-button.uhc-gold:focus, .card-button.uhc-gold:active, .my-chart.gold:focus, .my-chart.gold:active, .my-chart.uhc-gold:focus, .my-chart.uhc-gold:active, .newsletter-button.gold:focus, .newsletter-button.gold:active, .newsletter-button.uhc-gold:focus, .newsletter-button.uhc-gold:active {
    transform: translateY(0px);
    background-color: #eea904 !important;
    box-shadow: 0px 1px 6px -1px rgba(238, 169, 4, 0.75) !important; }

.usa-button.blue, .usa-button.uhc-blue, .usa-button-primary.blue, .usa-button-primary.uhc-blue, .card-button.blue, .card-button.uhc-blue, .my-chart.blue, .my-chart.uhc-blue, .newsletter-button.blue, .newsletter-button.uhc-blue {
  background-color: #002857 !important;
  box-shadow: 0px 1px 6px -1px rgba(0, 40, 87, 0.45);
  transition: 0.2s ease-in-out; }
  .usa-button.blue:hover, .usa-button.uhc-blue:hover, .usa-button-primary.blue:hover, .usa-button-primary.uhc-blue:hover, .card-button.blue:hover, .card-button.uhc-blue:hover, .my-chart.blue:hover, .my-chart.uhc-blue:hover, .newsletter-button.blue:hover, .newsletter-button.uhc-blue:hover {
    background-color: #003471 !important;
    box-shadow: 0px 3px 10px 0px rgba(0, 52, 113, 0.4);
    transform: translateY(-1px);
    color: white; }
  .usa-button.blue:focus, .usa-button.blue:active, .usa-button.uhc-blue:focus, .usa-button.uhc-blue:active, .usa-button-primary.blue:focus, .usa-button-primary.blue:active, .usa-button-primary.uhc-blue:focus, .usa-button-primary.uhc-blue:active, .card-button.blue:focus, .card-button.blue:active, .card-button.uhc-blue:focus, .card-button.uhc-blue:active, .my-chart.blue:focus, .my-chart.blue:active, .my-chart.uhc-blue:focus, .my-chart.uhc-blue:active, .newsletter-button.blue:focus, .newsletter-button.blue:active, .newsletter-button.uhc-blue:focus, .newsletter-button.uhc-blue:active {
    transform: translateY(0px);
    background-color: #002857 !important;
    box-shadow: 0px 1px 6px -1px rgba(0, 40, 87, 0.75) !important; }

/* Sidebar Styling */
aside .sb-links ul li .oncology {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(23, 55, 92, 0.71)), to(rgba(23, 55, 92, 0.66))), url(https://wvcancercenter.com/wp-content/uploads/2018/01/home-header.jpg);
  background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)), url(https://wvcancercenter.com/wp-content/uploads/2018/01/home-header.jpg); }

aside .sb-links ul li .ortho {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(23, 55, 92, 0.71)), to(rgba(23, 55, 92, 0.66))), url(https://wvorthocenter.com/wp-content/uploads/2019/10/uhc-ortho-hp-new-header-rocco-m3.jpg);
  background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)), url(https://wvorthocenter.com/wp-content/uploads/2019/10/uhc-ortho-hp-new-header-rocco-m3.jpg); }

aside .sb-links ul li .emergency {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(23, 55, 92, 0.71)), to(rgba(23, 55, 92, 0.66))), url(https://uhcemergencyroom.com/wp-content/uploads/2018/01/uhc-er-header3c.jpg);
  background-image: linear-gradient(rgba(23, 55, 92, 0.71), rgba(23, 55, 92, 0.66)), url(https://uhcemergencyroom.com/wp-content/uploads/2018/01/uhc-er-header3c.jpg);
  background-position: bottom; }

/* Infowindow Styling */
.infowindow {
  border-radius: 6px;
  color: #5e5e5e; }
  .infowindow p strong {
    color: #002857; }

/* Google Maps Marker Styling */
.gm-style .gm-style-iw-c {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  color: #002857;
  font-size: 16px; }
  .gm-style .gm-style-iw-c > div {
    padding: 0.2em; }
  .gm-style .gm-style-iw-c a {
    opacity: 1 !important;
    color: #002857;
    font-size: 16px; }
    .gm-style .gm-style-iw-c a:hover {
      color: #003471;
      text-decoration: none; }

footer .footer-column h5 + .textwidget p, footer .footer-column h5 + .textwidget ul {
  margin-top: 8px; }

footer .footer-column h5 + ul, footer .footer-column h5 + p {
  margin-top: 8px; }

footer .footer-column:first-of-type .textwidget strong {
  display: inline-block;
  line-height: 1.4; }

@media (min-width: 1300px) {
  footer .footer-column {
    padding-right: 100px !important; } }

footer a.calling-card {
  font-weight: normal !important;
  font-size: 36px !important; }

@media (max-width: 600px) {
  h1 {
    letter-spacing: 0px; }
  h2 {
    letter-spacing: -1px; } }

.usa-menu-btn, .usa-nav-close {
  background-color: #eea904;
  border-radius: 100%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
  color: #FFF !important;
  max-width: 4rem;
  transition: 0.25s ease-in-out; }
  .usa-menu-btn:hover, .usa-nav-close:hover {
    background-color: #fbb611; }

body.single-doctors main {
  margin-top: 3em; }

body.single-doctors .post-type-doctors h3 > p {
  margin-top: .15em;
  line-height: 1.2; }

body.single-doctors .post-type-doctors .dr-img {
  border-radius: 10px; }

body.single-doctors .post-type-doctors .dr-contact {
  margin-top: 1.5em;
  margin-bottom: 1.25em;
  max-width: 325px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border-radius: 6px !important; }
  body.single-doctors .post-type-doctors .dr-contact .content {
    padding: 22px 32px !important; }
    body.single-doctors .post-type-doctors .dr-contact .content > p:last-of-type {
      margin-bottom: 0.25em !important; }

body.single-doctors .post-type-doctors .col.m2 > .card.simple {
  margin-top: 0 !important; }
  body.single-doctors .post-type-doctors .col.m2 > .card.simple .no-bullet {
    margin-bottom: 0.2em !important; }

body.single-doctors .post-type-doctors .card a:hover {
  text-decoration: none !important; }

@media (max-width: 768px) {
  .intro-p {
    font-size: 20px !important; } }

.doc-card, .navigator-card {
  max-width: 435px;
  margin: 0 auto; }

.doc-card {
  margin-bottom: 25px; }
  .doc-card .doc-blue > div:nth-of-type(2) {
    padding-right: 8px;
    padding-bottom: 0; }

.treatment-card, .welcome-card, .tease.card {
  max-width: 600px;
  margin: 0 auto; }

aside.post-sidebar {
  border-radius: 6px 6px 0 0; }
  aside.post-sidebar .textwidget .lead {
    text-align: center; }
  aside.post-sidebar > div {
    border-radius: 6px 6px 0 0; }
  aside.post-sidebar > * {
    margin: 0 auto;
    max-width: 466px; }

aside:not(.post-sidebar):not(#info) {
  margin: 0 auto;
  max-width: 466px; }

ul.jump-links {
  border: 0.5px solid #f1f1f1 !important;
  display: flex;
  flex-wrap: wrap; }
  @media (max-width: 690px) and (min-width: 992px) {
    ul.jump-links {
      border-bottom: none !important; } }
  ul.jump-links li {
    width: 100%; }
    @media (min-width: 690px) and (max-width: 992px) {
      ul.jump-links li {
        width: 50%; }
        ul.jump-links li:nth-of-type(2n+1) {
          border-right: 0.5px solid #f1f1f1; } }
    ul.jump-links li:last-child {
      border-radius: 6px; }
      @media (min-width: 690px) and (max-width: 992px) {
        ul.jump-links li:last-child {
          border-radius: 0px 0px 0px 6px;
          border-bottom: none; } }

.section-blogs .tease.card {
  max-width: 600px;
  margin: 0 auto; }

@media (max-width: 922px) {
  .section-blogs h2.gold-slash {
    margin-bottom: 1em; } }

h2.gold-slash {
  font-weight: normal !important; }

.text-col-2 li {
  display: inline-block;
  width: 100%; }

.lity {
  background: rgba(0, 19, 41, 0.85) !important; }

.card:not(.no-hover):not(.quote-card):hover {
  transform: translate(-1px, -1px) !important; }

.card.no-hover {
  transform: translate(0px) !important; }

ul.styled {
  list-style: none;
  list-style-position: outside;
  margin-top: 0;
  padding-left: 1.2rem;
  z-index: 55555; }
  ul.styled li {
    line-height: 1.2;
    padding-bottom: 14px !important;
    margin-bottom: 14px !important;
    position: relative;
    padding-left: 18px;
    border-bottom: 1px solid #e4e4e4; }
    ul.styled li:before {
      position: absolute;
      font-family: FontAwesome;
      left: -0.75rem;
      font-size: 16px;
      top: 0px;
      content: "";
      color: #eea904; }
  ul.styled li > ul {
    margin-top: 1em;
    margin-bottom: 0; }
    ul.styled li > ul > li:last-of-type {
      border-bottom: none;
      margin-bottom: 0 !important; }
    ul.styled li > ul:before {
      content: "";
      position: absolute;
      left: -0.2em;
      top: 0.5em;
      bottom: 1em;
      border-left: 1px solid rgba(238, 169, 4, 0.3); }

.post-type-doctors .treatment-card, .blog-video-card {
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: 6px;
  overflow: hidden;
  max-width: 600px;
  margin: 2em auto; }
  .post-type-doctors .treatment-card > p, .blog-video-card > p {
    display: none; }
  .post-type-doctors .treatment-card > a, .blog-video-card > a {
    width: 100%;
    text-decoration: none; }
    .post-type-doctors .treatment-card > a > p, .blog-video-card > a > p {
      display: none; }
  .post-type-doctors .treatment-card:hover .treatment-wrap:after, .blog-video-card:hover .treatment-wrap:after {
    background: rgba(0, 40, 87, 0);
    transition: 0.25s ease-in-out; }
  .post-type-doctors .treatment-card:hover .treatment-wrap .treatment-still, .blog-video-card:hover .treatment-wrap .treatment-still {
    filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
    filter: grayscale(0%);
    filter: gray;
    transition: 0.25s ease-in-out; }
  .post-type-doctors .treatment-card .treatment-wrap, .blog-video-card .treatment-wrap {
    width: 100%;
    overflow: hidden;
    position: relative; }
    .post-type-doctors .treatment-card .treatment-wrap .treatment-still, .blog-video-card .treatment-wrap .treatment-still {
      filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      filter: gray;
      transition: 0.25s ease-in-out;
      display: block;
      min-width: 100%;
      width: 100%;
      position: relative; }
    .post-type-doctors .treatment-card .treatment-wrap:after, .blog-video-card .treatment-wrap:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 40, 87, 0.3);
      transition: 0.25s ease-in-out; }
  .post-type-doctors .treatment-card .bottom-treatment, .blog-video-card .bottom-treatment {
    z-index: 50 !important;
    position: relative !important;
    background-color: white !important;
    max-width: 100%;
    padding: 32px; }
    .post-type-doctors .treatment-card .bottom-treatment:before, .blog-video-card .bottom-treatment:before {
      content: "";
      position: absolute;
      width: 60px;
      height: 60px;
      top: -1.75em;
      left: 0;
      right: 0;
      margin: 0 auto;
      background-image: url(https://uhcspecialties.com/wp-content/uploads/2019/12/play-button.png);
      background-size: contain;
      background-repeat: no-repeat; }
    .post-type-doctors .treatment-card .bottom-treatment p:first-child, .blog-video-card .bottom-treatment p:first-child {
      margin: 10px 0px 0px 0px;
      width: 100%;
      -ms-flex: 0 1 auto; }
  .post-type-doctors .treatment-card h5, .blog-video-card h5 {
    display: inline-block;
    height: fit-content;
    width: 65%;
    line-height: 1.1;
    margin: auto;
    position: absolute;
    font-size: 21px;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 600;
    top: 0;
    color: #002857;
    bottom: 0; }
    @media (min-width: 600px) {
      .post-type-doctors .treatment-card h5, .blog-video-card h5 {
        font-size: 23px; } }
    .post-type-doctors .treatment-card h5 span, .blog-video-card h5 span {
      font-size: 0.6em;
      font-weight: 600;
      color: #002857; }

.sidebar-card.housecall, .post-sidebar.housecall, .sidebar-card-lp.housecall {
  padding: 0;
  margin-bottom: 1em;
  border-radius: 6px !important;
  overflow: hidden; }
  .sidebar-card.housecall .sb-links ul, .post-sidebar.housecall .sb-links ul, .sidebar-card-lp.housecall .sb-links ul {
    padding-left: 0 !important; }
    .sidebar-card.housecall .sb-links ul li, .post-sidebar.housecall .sb-links ul li, .sidebar-card-lp.housecall .sb-links ul li {
      padding: 0 !important; }
      .sidebar-card.housecall .sb-links ul li:before, .post-sidebar.housecall .sb-links ul li:before, .sidebar-card-lp.housecall .sb-links ul li:before {
        content: "" !important; }
  .sidebar-card.housecall .textwidget, .post-sidebar.housecall .textwidget, .sidebar-card-lp.housecall .textwidget {
    padding: 24px !important;
    padding-top: 0 !important; }
  .sidebar-card.housecall > .sidebar-widget:first-of-type, .post-sidebar.housecall > .sidebar-widget:first-of-type, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type {
    background-image: url(https://www.uhcspecialties.com/wp-content/themes/uhc-uswds/img/housecall_background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    background-color: rgba(255, 255, 255, 0.4);
    background-blend-mode: lighten; }
    .sidebar-card.housecall > .sidebar-widget:first-of-type .textwidget, .post-sidebar.housecall > .sidebar-widget:first-of-type .textwidget, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .textwidget {
      padding-top: 24px !important; }
    .sidebar-card.housecall > .sidebar-widget:first-of-type .lead, .post-sidebar.housecall > .sidebar-widget:first-of-type .lead, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .lead {
      margin: 0.5em 0;
      font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
      font-size: 1.8em;
      line-height: 1.2;
      color: #757575;
      margin-bottom: 0 !important; }
      @media (min-width: 1200px) and (max-width: 1450px) {
        .sidebar-card.housecall > .sidebar-widget:first-of-type .lead, .post-sidebar.housecall > .sidebar-widget:first-of-type .lead, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .lead {
          font-size: 1.6em; }
          .sidebar-card.housecall > .sidebar-widget:first-of-type .lead + p, .post-sidebar.housecall > .sidebar-widget:first-of-type .lead + p, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .lead + p {
            font-size: 1.2em !important; }
            .sidebar-card.housecall > .sidebar-widget:first-of-type .lead + p + a, .post-sidebar.housecall > .sidebar-widget:first-of-type .lead + p + a, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .lead + p + a {
              font-size: 1.4em !important; } }
      .sidebar-card.housecall > .sidebar-widget:first-of-type .lead + p, .post-sidebar.housecall > .sidebar-widget:first-of-type .lead + p, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .lead + p {
        font-size: 1.4em;
        margin: 0;
        line-height: 1.2em; }
        .sidebar-card.housecall > .sidebar-widget:first-of-type .lead + p + a, .post-sidebar.housecall > .sidebar-widget:first-of-type .lead + p + a, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .lead + p + a {
          text-decoration: none;
          color: #eea904;
          font-family: 'Helvetica Neue', Helvetica, sans-serif;
          font-weight: 700;
          font-size: 1.6em;
          margin-top: 0.5em;
          display: block; }
          @media (min-width: 992px) {
            .sidebar-card.housecall > .sidebar-widget:first-of-type .lead + p + a, .post-sidebar.housecall > .sidebar-widget:first-of-type .lead + p + a, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .lead + p + a {
              font-size: 1.8em; } }
          .sidebar-card.housecall > .sidebar-widget:first-of-type .lead + p + a::after, .post-sidebar.housecall > .sidebar-widget:first-of-type .lead + p + a::after, .sidebar-card-lp.housecall > .sidebar-widget:first-of-type .lead + p + a::after {
            content: "";
            background-repeat: no-repeat;
            width: 1.6em;
            height: 2.5em;
            display: inline-block;
            float: right;
            margin-top: -0.9em; }
  .sidebar-card.housecall .newsletter-button, .post-sidebar.housecall .newsletter-button, .sidebar-card-lp.housecall .newsletter-button {
    display: block;
    margin: 1em 0 0;
    padding: 0.5em 0.75em;
    text-align: center;
    text-decoration: none;
    background-color: #eea904;
    border-radius: 6px;
    font-family: 'Helvetica Neue', helvetica, sans-serif;
    font-weight: 700;
    color: white;
    font-size: 1.5em; }
  @media (max-width: 1199px) {
    .sidebar-card.housecall, .post-sidebar.housecall, .sidebar-card-lp.housecall {
      margin-top: 20px !important; } }

.sidebar-card .custom-html-widget > div.housecall, .post-sidebar .custom-html-widget > div.housecall, .sidebar-card-lp .custom-html-widget > div.housecall {
  background-image: url(https://www.uhcspecialties.com/wp-content/themes/uhc-uswds/img/housecall_background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-color: rgba(255, 255, 255, 0.4);
  background-blend-mode: lighten;
  padding: 1.75em;
  margin-top: 0; }
  .sidebar-card .custom-html-widget > div.housecall .lead, .post-sidebar .custom-html-widget > div.housecall .lead, .sidebar-card-lp .custom-html-widget > div.housecall .lead {
    margin: 0.5em 0; }
  .sidebar-card .custom-html-widget > div.housecall .newsletter-button, .post-sidebar .custom-html-widget > div.housecall .newsletter-button, .sidebar-card-lp .custom-html-widget > div.housecall .newsletter-button {
    display: block;
    margin: 1em 0 0;
    padding: 0.5em 0.75em;
    text-align: center;
    text-decoration: none;
    background-color: #eea904;
    border-radius: 6px;
    font-family: 'Helvetica Neue', helvetica, sans-serif;
    font-weight: 700;
    color: white;
    font-size: 1.5em !important; }

.sidebar-card.housecall {
  margin-top: 0 !important; }

.cc-banner {
  z-index: 9999999 !important; }

.cc-window.cc-banner {
  background-color: #eea904 !important;
  background-image: linear-gradient(-15deg, #EAAA00, #fdba24) !important;
  color: #002857 !important;
  font-family: "Helvetica Neue", Helvetica, sans-serif !important;
  border-top: 1px solid white !important; }
  .cc-window.cc-banner a {
    color: #002857 !important; }
    .cc-window.cc-banner a:hover {
      text-decoration: none !important; }
  .cc-window.cc-banner .cc-btn.cc-allow {
    background-color: #002857 !important;
    color: white !important;
    transition: 0.25s ease-in-out !important; }
    .cc-window.cc-banner .cc-btn.cc-allow:hover {
      background-color: #003471 !important; }
  @media (max-width: 687px) {
    .cc-window.cc-banner {
      flex-direction: column !important; }
      .cc-window.cc-banner .cc-message {
        margin-left: 1em !important;
        padding: 0.75em; }
      .cc-window.cc-banner .cc-compliance {
        width: 100%; } }

@media (max-width: 600px) {
  body.blog .ind-blog .archive-card h2 {
    font-size: 22px !important; } }

@media (max-width: 600px) {
  body.blog .ind-blog .archive-card:nth-of-type(1) h2 {
    font-size: 26px !important; } }

@media (max-width: 600px) {
  .content-area .card.callout {
    padding-top: 16px !important; } }

.gform_wrapper div.validation_error {
  color: #da233a;
  border-color: #da233a; }

.housecall-videos-sidebar h4 {
  font-family: 'Helvetica Neue Condensed', Helvetica, sans-serif;
  color: #002857;
  font-size: 28px; }

.housecall-videos-sidebar .card {
  border-radius: 6px;
  margin-bottom: 1em !important; }

.housecall-videos-sidebar .hc-image-container {
  position: relative;
  opacity: 1;
  transition: 0.25s ease-in-out; }
  .housecall-videos-sidebar .hc-image-container:after {
    content: url("https://uhchousecall.com/wp-content/themes/uhc-uswds/img/play-button.svg");
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    height: 60px;
    width: 60px;
    transition: 0.25s ease-in-out;
    pointer-events: none;
    filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.5)); }

.housecall-videos-sidebar img {
  display: block;
  border-radius: 6px 6px 0 0; }

.housecall-videos-sidebar a {
  text-decoration: none; }
  .housecall-videos-sidebar a p {
    margin: 0;
    padding: 1.25em;
    color: #8e99ad !important; }
  .housecall-videos-sidebar a:hover .hc-image-container {
    opacity: 0.9; }
    .housecall-videos-sidebar a:hover .hc-image-container:after {
      transform: scale(1.05); }

@media (max-width: 992px) {
  .housecall-videos-sidebar .card {
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1), 0 2px 10px -2px rgba(0, 0, 0, 0.05) !important; } }

.uhc-flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.75em 0; }
  .uhc-flex-container > div:first-of-type {
    margin-right: 1.5em; }
  .uhc-flex-container.reverse {
    flex-direction: row-reverse; }
    .uhc-flex-container.reverse > div:first-of-type {
      margin-right: 0; }
    .uhc-flex-container.reverse > div:nth-of-type(2) {
      margin-right: 1.5em; }
  .uhc-flex-container .flex-container__portrait {
    flex: 1;
    max-width: 350px; }
    .uhc-flex-container .flex-container__portrait img {
      width: 100%;
      height: auto;
      display: block;
      max-width: 400px; }
      .uhc-flex-container .flex-container__portrait img.circle {
        border-radius: 100%; }
  .uhc-flex-container .flex-container__image {
    flex: 2;
    max-width: 450px; }
    .uhc-flex-container .flex-container__image img {
      width: 100%;
      height: auto;
      display: block; }
  .uhc-flex-container .flex-container__content {
    flex: 3; }
    .uhc-flex-container .flex-container__content.larger p {
      font-size: 1.2em; }
    .uhc-flex-container .flex-container__content.blue p {
      color: #002857; }
  @media (max-width: 768px) {
    .uhc-flex-container {
      flex-direction: column; }
      .uhc-flex-container.reverse {
        flex-direction: column; }
      .uhc-flex-container.mobile-reverse {
        flex-direction: column-reverse; } }

.uhc-bar {
  cursor: default; }
  .uhc-bar .usa-nav-container p {
    cursor: default; }

.social-link {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  width: 1.35em;
  height: 1.35em;
  font-size: 1.05em !important;
  opacity: 0.85;
  transition: 0.25s ease-in-out;
  text-decoration: none !important;
  color: white !important; }
  .social-link:hover {
    opacity: 1; }
  .social-link:last-of-type {
    margin-right: 0; }

.social-link--container {
  margin-bottom: 1em;
  cursor: default; }
  .social-link--container .social-link {
    font-size: 1.35em !important; }
    .social-link--container .social-link:first-of-type {
      margin-left: 0; }

.mobile-social-menu {
  display: flex !important;
  padding: 0 0 12px 25px;
  border-bottom: 1px solid #0033a0;
  justify-content: flex-end; }
  .mobile-social-menu .social-link {
    border: none !important;
    width: 2em;
    height: 2em;
    padding: 0 !important;
    font-size: 1.25em !important;
    background-color: transparent; }
    .mobile-social-menu .social-link:hover {
      background-color: transparent; }
  @media (min-width: 950px) {
    .mobile-social-menu {
      display: none !important; } }
