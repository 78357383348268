// These variables drive almost every aspect of the U.S. Web Design
// system. Every variable is declared as !default so that it can be
// overridden by a previous import.
//
// XXX Do not modify this file!
//
// If you want to override a variable, set that variable in one of your
// own partials, then @import '{path/to/uswds/}core/variables'.

// Typography
// Removing the !default from $em-base so we are not inheriting that
// value from Bourbon.
$em-base:             10px;
$base-font-size:      1.7rem !default;
$small-font-size:     1.4rem !default;
$lead-font-size:      2rem !default;
$title-font-size:     5.2rem !default;
$h1-font-size:        4rem !default;
$h2-font-size:        3rem !default;
$h3-font-size:        2rem !default;
$h4-font-size:        1.7rem !default;
$h5-font-size:        1.5rem !default;
$h6-font-size:        1.3rem !default;
$base-line-height:    1.5 !default;
$heading-line-height: 1.3 !default;
$lead-line-height:    1.7 !default;

$font-sans:           'Helvetica Neue' !default;
$font-serif:          'Helvetica Neue' !default;

$font-normal:         400 !default;
$font-bold:           700 !default;

$global-transition: 0.25s ease-in-out;
$global-transition10: 0.1s ease-in-out;
$global-line-height: 1.3;
$helvetica: 'Helvetica Neue', Helvetica, sans-serif;
$helvetica-condensed: 'Helvetica Neue Condensed', Helvetica, sans-serif;
.helvetica-condensed {
    font-family: $helvetica-condensed!important;
}
$iowan-old: 'Iowan Old Style Bold', Georgia, serif;


@mixin StyledList( $bulletcolor: $uhc-gold, $bulletcontent: '\f0c8' ) {
    list-style: none;
    list-style-position: outside;
    margin-top: 0;
    padding-left: 1.2rem;
    li {
        line-height: 1.2;
        padding-bottom: 14px!important;
        margin-bottom: 14px!important;
        position: relative;
        padding-left: 18px;
        border-bottom: 1px solid #e4e4e4;
        &:before {
            position: absolute;
            font-family: FontAwesome;
            left: -0.75rem;
            font-size: 16px;
            top: 0px;
            content: $bulletcontent;
            color: $bulletcolor;
        }
    }
}


// Color
$uhc-black: #2b2b2b;
$uhc-white: #fff;
$uhc-blue: #002857;
$uhc-gold: #eea904;
$uhc-med-blue: #31a3d3;
$uhc-periwinkle: #6b60dd;
$uhc-yellow-green: #c0a83f;
$uhc-white-20: transparentize($uhc-white, 0.8);
$uhc-light-blue: #cfe9f4;
$uhc-yellow: #e8d27c;
$uhc-red: #da233a;
$uhc-cream: #f3eac5;
$uhc-violet: #7c8ca6;
$uhc-seafoam: #86a8aa;
$uhc-wvu: #eaaa00;
$uhc-olive: #94a497;
$uhc-warmgray:#9b918f;

$uhc-mobile-bg: #005EB8;
$uhc-mobile-hover: #0033A0;

$primary-color: $uhc-blue;
$secondary-color: $uhc-gold;
$success-color: $uhc-med-blue;
$error-color: $uhc-red;
$link-color: $uhc-periwinkle;

$color-primary:              $uhc-blue !default;
$color-primary-darker:       darken($uhc-blue, 10%) !default;
$color-primary-darkest:      darken($uhc-blue, 20%) !default;

$color-primary-alt:          $uhc-gold !default;
$color-primary-alt-dark:     darken($uhc-gold, 10%) !default;
$color-primary-alt-darkest:  darken($uhc-gold, 20%) !default;
$color-primary-alt-light:    lighten($uhc-gold, 10%) !default; // lighten($color-primary-alt, 60%)
$color-primary-alt-lightest: lighten($uhc-gold, 20%) !default; // lighten($color-primary-alt, 90%)

$color-secondary:            $uhc-med-blue !default;
$color-secondary-dark:       darken($uhc-med-blue, 10%) !default;
$color-secondary-darkest:    darken($uhc-med-blue, 20%) !default;
$color-secondary-light:      lighten($uhc-med-blue, 10%) !default; // lighten($color-secondary, 60%)
$color-secondary-lightest:   lighten($uhc-med-blue, 20%) !default; // lighten($color-secondary, 90%)

$color-white:                #ffffff !default;
$color-base:                 #212121 !default;
$color-black:                #000000 !default;

$color-gray-dark:            #323a45 !default;
$color-gray:                 #5b616b !default; // lighten($color-gray-dark, 20%)
$color-gray-medium:          #757575 !default; // lightest gray that passes color contrast
$color-gray-light:           #aeb0b5 !default; // lighten($color-gray-dark, 60%)
$color-gray-lighter:         #d6d7d9 !default; // lighten($color-gray-dark, 80%)
$color-gray-lightest:        #f1f1f1 !default; // lighten($color-gray-dark, 91%)

$color-gray-warm-dark:       #494440 !default;
$color-gray-warm-light:      #e4e2e0 !default; // lighten($color-gray-warm-dark, 90%)
$color-gray-cool-light:      #dce4ef !default; // lighten($color-primary, 90%)

$color-gold:                 #fdb81e !default;
$color-gold-light:           #f9c642 !default; //  lighten($color-gold, 20%)
$color-gold-lighter:         #fad980 !default; //  lighten($color-gold, 60%)
$color-gold-lightest:        #fff1d2 !default; //  lighten($color-gold, 83%)

$color-green:                #2e8540 !default;
$color-green-light:          #4aa564 !default; // lighten($color-green, 20%)
$color-green-lighter:        #94bfa2 !default; // lighten($color-green, 60%)
$color-green-lightest:       #e7f4e4 !default; // lighten($color-green, 60%)

$color-cool-blue:            #205493 !default;
$color-cool-blue-light:      #4773aa !default; // lighten($color-cool-blue, 20%)
$color-cool-blue-lighter:    #8ba6ca !default; // lighten($color-cool-blue, 60%)
$color-cool-blue-lightest:   #dce4ef !default; // lighten($color-cool-blue, 90%)

$color-focus:                #3e94cf !default;
$color-visited:              #4c2c92 !default;
$color-shadow:               rgba(#000, 0.3) !default;

// Media Query Ranges
$small-screen-up: 767px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

$breakpoint-400: 400px;
$breakpoint-phone: 600px;
$breakpoint-ipad: 1148px;
$breakpoint-small-tablet: 767px;
$breakpoint-900: 900px;

// over write
$navbar-height: 80px;
$navbar-height-mobile: 80px;
$navbar-font-size: 1rem;
$navbar-font-color: $uhc-blue;
$navbar-brand-font-size: 2.1rem;
$nav-width:           1050px;

$box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.14), 5px 6px 4px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
$box-shadow-large: 0 20px 30px rgba(0, 0, 0, 0.1);
$box-shadow-button: 0px 2px 8px rgba(0,0,0,.4);

// Relative font and image file paths
$font-path:   './dist/fonts' !default;
$image-path:  './dist/img' !default;

// Set $asset-pipeline to true if you're using the Rails Asset Pipeline
$asset-pipeline:      false !default;

// Magic Numbers
$text-max-width:      53rem !default;
$lead-max-width:      77rem !default;
$site-max-width:      1040px !default;
$site-margins:        3rem !default;
$site-margins-mobile: 1.5rem !default;
$article-max-width:   600px !default;
$input-max-width:     46rem !default;
$border-radius:       3px !default;
$focus-shadow:        0 0 3px $color-focus, 0 0 7px $color-focus !default;

// 44 x 44 pixels hit target following Apple iOS Human Interface
// Guidelines
$hit-area: 4.4rem !default;

$card-padding: 16px;